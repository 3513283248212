.card-one {
	border: 1px solid $color-three;
	border-radius: $border-one;
	padding: 0.62rem 1rem 0.87rem;

	@media (min-width: 1000px) {
		padding: 1rem 1.6rem 1.4rem;
	}

	&__content {
		text-decoration: none;
		display: block;
		flex: 1;
		justify-content: space-between;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__heading {
		@include font-preset--4;
		margin: 0;
		color: $color-three;

	}

	&__copy {
		@include font-preset--7;
		margin-top: 0.6ex;
	}

	.card-one__categories {
		margin-top: 0.6rem;
	}
}
