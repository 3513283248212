@font-face {
	font-family: 'Roboto';
	src: url('/fonts/Roboto-Regular.eot');
	src: url('/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Roboto-Regular.woff') format('woff2'),
		url('/fonts/Roboto-Regular.woff') format('woff'),
		url('/fonts/Roboto-Regular.ttf') format('truetype'),
		url('/fonts/Roboto-Regular.svg') format('svg');
	font-weight: 400;
	font-style: regular;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/Roboto-Medium.eot');
	src: url('/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Roboto-Medium.woff') format('woff2'),
		url('/fonts/Roboto-Medium.woff') format('woff'),
		url('/fonts/Roboto-Medium.ttf') format('truetype'),
		url('/fonts/Roboto-Medium.svg') format('svg');
	font-weight: 600;
	font-style: regular;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/fonts/Roboto-Bold.eot');
	src: url('/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Roboto-Bold.woff') format('woff2'),
		url('/fonts/Roboto-Bold.woff') format('woff'),
		url('/fonts/Roboto-Bold.ttf') format('truetype'),
		url('/fonts/Roboto-Bold.svg') format('svg');
	font-weight: 800;
	font-style: regular;
	font-display: swap;
}
