.categories {
	list-style: none;
	padding: 0;
	margin: 0;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__item {
		font-size: 0.8rem;
		font-weight: 400;
		display: inline-block;
		background-color: $color-three;
		color: $color-two;
		border-radius: $border-three;
		margin: 0 0.25rem 0.25rem 0;
		padding: 0 0.25rem 0.1rem;

		a {
			text-decoration: none;
			color: $color-two
		}
	}
	
	&__toggle {
		display: inline-block;
		padding: 0;
		margin: 0 0.25rem 0.25rem 0;

		&--hidden {
			display: none;
		}
		
		button {
			display: inline-block;
			background: unset;
			border: unset;
			appearance: none;
			cursor: pointer;
			margin: 0;
			padding: 0 0.25rem 0.1rem;
			font-size: 0.8rem;
			text-decoration: underline;
		}
	}
}

