.header {
	background-color: $color-three;

	&__container-upper,
	&__container-lower {
		@media (min-width: $nav-breakpoint) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
		}
	}

	&__container-lower {
		padding: 1rem 0;

		@media (min-width: $nav-breakpoint) {
			padding: 1.5rem 0 0;
		}
	}


	&__container-upper {
		padding-top: 0.5rem;
		padding-bottom: 0.55rem;
		background-color: $color-seven;
		max-width: unset;
	}

	&__announcement {
		margin: auto;

		p {
			margin: 0;
			display: inline;
		}

		@media (max-width: $nav-breakpoint) {
			text-align: center;
		}
	}

	&__announcement-title {
		color: $color-one;
	}

	&__announcement-link {
		color: $color-one;
	}

	&__upper-nav {
		color: $color-one;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		li {
			display: inline;
			font-size: 0.9rem;
			font-weight: 800;
			margin-left: 1.5rem;

			&:first-of-type {
				margin: 0;
			}
		}

		a {
			text-decoration: none;
		}

		@media (max-width: $nav-breakpoint) {
			display: none;
		}
	}

	&__logo {
		display: block;
		max-width: 190px;
		margin: auto;
	}

	hr {
		margin: 1.5rem 0 0 0;
		border: 0;
		height: 1px;
		background: $color-five;
		opacity: 0.3;
	}

	&__searchbar {
		background-color: $color-four;
		padding-top: 4.5rem;
		padding-bottom: 3.5rem;
		overflow: hidden;

		&--active {}

		.form {
			display: flex;
			margin: auto;
		}

		.button {
			border-radius: 0 $border-three $border-three 0;
			width: 220px;
		}

		input[type="text"] {
			border: 1px solid $color-three;
			border-radius: $border-three 0 0 $border-three;
		}
	}
	
	&__searchbar-close {
		position: absolute;
		top: 1.5rem;
		right: 2rem;
		appearance: none;
		border: 0;
		background-color: unset;
		cursor: pointer;
		width: 1.5rem;
		height: 1.5rem;
		margin: 0;
		padding: 0;
	}

	&__button {
		font-size: 0.9rem;
		font-weight: 800;
		padding: 0.5ex 1ex 0.7ex;
	}

	&__icon {
		position: absolute;
		top: 2rem;
		right: 2rem;
		display: block;
		width: 1.5rem;
		height: 1.5rem;
		appearance: none;
		padding: 0;
		border: 0;
		background: transparent;

		@media (min-width: $nav-breakpoint) {
			right: unset;
			left: 2rem;
		}
	}

	&__buttons {
		display: none;

		@media (min-width: $nav-breakpoint) {
			position: absolute;
			top: 2rem;
			right: 2rem;
			display: flex;
			align-items: center;

			> a {
				white-space: nowrap;
				margin: 0 0 0 1.4ex;
			}

			.button--two {
				font-size: 1rem;
			}
		}
	}
}

