@mixin arrowList {
	border-bottom: 1px solid $color-four;
	padding: 1ex 0 1ex 2.5ex;
	margin: 0;
	display: inline-block;
	width: 100%;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 1.4ex;
		width: 2ex;
		height: 2ex;
		background-image: url('/img/icon-arrow-right.png');
		background-size: 1.2ex;
		background-position: 50%;
		background-repeat: no-repeat;
	}

	@media (min-width: $nav-breakpoint) {
		padding: 1.3ex 0 1.3ex 2.5ex;
	}
}