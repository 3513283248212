.intro {
	margin-bottom: 2.18rem;

	&__container {}

	&__heading {
		@include font-preset--3;
		color: $color-three;

		&--one {
			@include font-preset--2;
		}

		&--three {
			@include font-preset--4;
		}
	}

	&__copy {}
}
