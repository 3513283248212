@media (max-width: $nav-breakpoint) {
	.nav {
		position: static;

		.js-dropdown-toggle {
			cursor: pointer;
		}

		a {
			text-decoration: none;
		}

		&__col--spacer {
			display: none;
		}

		&__dropdown-trigger {
			appearance: none;
			background-color: transparent;
			border: 0;
			width: 1.4rem;
			height: 1.8rem;
			padding: 0;
			z-index: 1;
			cursor: pointer;
			position: absolute;
		}

		&__actions {
			position: absolute;
			left: 2rem;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			gap: 1rem;
			z-index: 3;
		}

		&__toggle {
			cursor: pointer;
			background-color: transparent;
			border: 0;
			display: block;
			width: 1.8rem;
			height: 1.5rem;
			background-image: url('/img/icon-nav-open.png');
			background-size: cover;
			background-position: 50%;

			&--active {
				background-image: url('/img/icon-nav-close.png');
			}
		}

		&__back {
			cursor: pointer;
			background-color: transparent;
			border: 0;
			display: none;
			width: 1.8rem;
			height: 1.5rem;
			background-image: url('/img/icon-nav-back.png');
			background-size: 0.8rem;
			background-repeat: no-repeat;
			background-position: 50%;

			&--active {
				display: block;
			}
		}

		&__list {
			list-style: none;
			margin: 0;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			min-height: 100vh;
			max-width: 470px;
			background-color: $color-two;
			color: $color-three;
			z-index: 2;
			padding: 6rem 2rem 3rem;
			left: -100%;
			transition: left 0.3s;

			&--active {
				left: 0;
			}

			> .nav__item {
				position: static;
				margin: 0;

				&:not(&--button, &--small) {
					> a {
						@include arrowList;
					}
				}
			}
		}


		&__item {
			font-size: 1.06rem;
			font-weight: 800;

			&--icon {
				display: none !important;
			}


			&--small {
				margin-top: 4rem !important;

				+ .nav__item--small {
					margin: 1.6ex 0 0 !important;
				}
				
				button {
					appearance: none;
					border: 0;
					margin: 0;
					padding: 0;
					cursor: pointer;
					text-align: left;
					background: unset;
					color: $color-three;
					margin-bottom: 1.5ex;
				}
			}

			&--button {
				.button {
					width: 100%;
					margin-top: 1.6ex;
				}
			}

			&--mobile-hide {
				display: none;
			}

			&--dropdown {
				display: flex;
				align-items: center;

				> a {
					width: 100% !important;
					margin-right: -1rem !important;
					
				}
			}
		}

		&__icon {
			display: none;
		}

		&__dropdown {
			position: absolute;
			left: -100%;
			top: 0;
			width: 100%;
			max-width: 470px;
			background-color: $color-two;
			color: $color-three;
			z-index: 2;
			padding: 6rem 2rem 3rem;
			height: 100%;
			transition: left 0.3s;

			&--active {
				left: 0;
			}

			h2 {
				margin-top: 0;
			}

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				margin-bottom: 1.5rem;
			}

			li {
				margin: 0;
			}

			.nav__item {
				@include arrowList;
			}
		}

		&__dropdown-wrapper {
			overflow: scroll;
			height: 100%;
			padding-bottom: 3rem;
		}

		&__heading {
			margin: 0;

			h2 {
				font-size: 0.87rem;
				margin: 0;
				color: $color-four;
			}
		}

		&__offer {
			&-heading {
				font-size: 1.1rem;
				margin: 0;
			}

			p {
				@include arrowList;
				border-bottom: 0;
				padding-bottom: 0;
				margin: 0;
			}
		}

		&__mobile-buttons {
			margin-top: 2rem;

			.button--plain {
				color: $color-three;
				text-decoration: underline;

				&:hover {
					color: $color-three !important;
				}
			}

			> a {
				margin: 0 0 0.6rem;
			}
		}
	}
}
