.accordion {
	margin-bottom: 3rem;

	@media (min-width: 900px) {
		margin-bottom: 4rem;
	}

	&__container {
		list-style: none;
	}

	&__item {
		border-top: 1px solid $color-five;
		margin: 0;

		&:last-of-type {
			border-bottom: 1px solid $color-five;
		}

	}

	&__title {
		cursor: pointer;
		appearance: none;
		background-color: transparent;
		border: 0;
		margin: 0;
		padding: 0;
		width: 100%;
		text-align: left;

		> * {
			@include font-preset--6;
			margin: 1ex 0;
			color: $color-three;
			padding-right: 4ex;

			&::after {
				content: "";
				position: absolute;
				right: 0;
				top: 50%;
				width: 2ex;
				height: 2ex;
				background-image: url('/img/icon-arrow-down.png');
				background-repeat: no-repeat;
				background-size: 1.5ex;
				background-position: 50%;
				transform: translateY(-50%) rotate(180deg);
				transition: transform 0.2s;
			}
		}

		&--active {
			> * {
				&::after {
					transform: translateY(-50%) rotate(0);
				}
			}
		}
	}

	&__content {
		padding: 0.5rem 0 1rem;

		> * {
			&:first-of-type {
				margin: 0;
			}
		}
	}
}



