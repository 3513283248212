.card-four {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&__heading {
		@include font-preset--4;
		margin: 0;
		color: $color-three;

	}

	&__copy {
		@include font-preset--7;
		margin-top: 0.6ex;

		&--email {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	&__image {
		width: 11.25rem;
		margin-right: 1.8rem;
		border-radius: 100%;
		overflow: hidden;
	}

	&__text {
		width: calc(100% - 13.5rem);
	}

	.card-four__categories {
		margin-top: 0.6rem;
	}
}