
.pagination {
	display: inline-flex;
	margin: 1rem 0 5rem;

	&__button {
		padding: 0.25rem 1rem;
		background-color: #3D3051;
		color: #ffffff;
		border-radius: 5px;
		text-decoration: none;
	}

	&__current {
		padding: 0.25rem 1rem;
		border: 1px solid;
		border-radius: 5px;
	}

	&__list {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			margin: 0;
			padding: 0.25rem 1rem;
		}
	}
}