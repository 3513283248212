$font-bp-1: 1200px;
$font-bp-2: 700px;

@mixin font-preset--1 {
	font-size: 1.8rem;
	font-weight: 800;
	margin-bottom: -0.5ex;

	@media (min-width: $font-bp-2) {
		font-size: 2.34rem;
	}

	@media (min-width: $font-bp-1) {
		font-size: 3.75rem;
	}
}

@mixin font-preset--2 {
	font-size: 1.5rem;
	font-weight: 800;
	margin-bottom: -0.5ex;

	@media (min-width: $font-bp-2) {
		font-size: 1.8rem;
	}

	@media (min-width: $font-bp-1) {
		font-size: 2.87rem;
	}
}

@mixin font-preset--3 {
	font-size: 1.33rem;
	font-weight: 800;
	margin-bottom: -0.5ex;

	@media (min-width: $font-bp-2) {
		font-size: 1.6rem;
	}

	@media (min-width: $font-bp-1) {
		font-size: 2.1rem;
	}
}

@mixin font-preset--4 {
	font-size: 1.2rem;
	font-weight: 800;
	margin-bottom: -0.5ex;

	@media (min-width: $font-bp-1) {
		font-size: 1.5rem;
	}
}

@mixin font-preset--5 {
	font-size: 1.12rem;
	font-weight: 800;
}

@mixin font-preset--6 {
	font-size: 1.12rem;
	font-weight: 600;
}

@mixin font-preset--7 {
	font-size: 1.12rem;
	font-weight: 400;
}

@mixin font-preset--8 {
	font-size: 1rem;
	font-weight: 400;
}

@mixin font-preset--9 {
	font-size: 1.37rem;
	font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
	@include font-preset--8;
	margin: 2.6ex 0 0;
	line-height: 1.35;
}

a:not([class]) {
	color: currentColor;
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-decoration-color: var(--color-one);
	transition: color 0.2s ease;
	text-decoration-skip-ink: auto;

	// &:hover {
	// 	color: var(--color-one);
	// }
}

ul,
ol {
	list-style: disc;
	padding-left: 1.8ex;
}

li {
	margin: 2.6ex 0 0;

	+li {
		margin: 1.6ex 0 0;
	}
}

.font-preset--1,
h1 {
	@include font-preset--1;
}

.font-preset--2,
h2 {
	@include font-preset--2;
}

.font-preset--3,
h3 {
	@include font-preset--3;
}

.font-preset--4,
h4 {
	@include font-preset--4;
}

.font-preset--5,
h5 {
	@include font-preset--5;
}

.font-preset--6,
h6 {
	@include font-preset--6;
}



// html {
// 	color: $colour__body-text;
// 	font-family: $type__body-font-family;
// 	font-size: $type__base-font-size;
// 	line-height: $type__base-line-height;
// 	overflow-x: hidden;
// 	-webkit-font-smoothing: antialiased;
// }

// a,
// .js-link {
// 	color: $colour__body-text;

// 	&:hover {
// 		text-decoration: none;
// 	}
// }

// h1,
// .h1,
// h2,
// .h2,
// h3,
// .h3,
// h4,
// .h4,
// h5,
// .h5,
// h6,
// .h6,
// p,
// ul,
// ol {
// 	margin: 0 0 math.div($gutter, 2);
// }

// h1,
// .h1,
// h2,
// .h2,
// h3,
// .h3,
// h4,
// .h4,
// h5,
// .h5,
// h6,
// .h6 {
// 	font-family: $type__heading-font-family;
// 	font-weight: 400;
// }

// h1,
// .h1 {
// 	@include resp-font-size(28, 30, 32, 34);
// }

// h2,
// .h2 {
// 	@include resp-font-size(24, 28, 30);
// }

// h3,
// .h3 {
// 	@include resp-font-size(20, 22, 24);
// }

// h4,
// .h4 {
// 	@include resp-font-size(18, 20);
// }

// h5,
// .h5 {
// 	@include resp-font-size(16, 18);
// }

// h6,
// .h6 {
// 	@include resp-font-size(14);
// }


