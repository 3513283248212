.hero {
	background-color: $color-three;
	color: $color-two;
	margin-bottom: 3rem;

	@media (min-width: 900px) {
		margin-bottom: 4rem;
	}

	@media (min-width: 1100px) {
		margin-bottom: calc(16vw + 4rem);
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: column-reverse;
		padding-top: 2rem;
		padding-bottom: 2rem;

		@media (min-width: 800px) {
			flex-direction: row;
			padding-top: 3.5rem;
			padding-bottom: 3.5rem;
		}
	}

	&__text {
		width: 100%;

		@media (min-width: 800px) {
			width: 50%;
		}
	}

	&__image {
		width: 100%;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		height: 60vw;
		max-height: 800px;
		border-radius: $border-one;

		@media (min-width: 800px) {
			width: calc(50% - 3.5rem);
			height: 45vw;
		}

		@media (min-width: 1100px) {
			right: -2rem;
			margin-bottom: -16vw;
		}
	}

	&__heading {
		@include font-preset--1;
		margin-top: 1ex;

		@media (min-width: 800px) {
			margin-top: 0;
		}
	}

	&__copy {
		@include font-preset--4;
		margin-top: 1.5ex;
	}
}
