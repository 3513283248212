.hero-plain {
	background-color: $color-three;
	color: $color-two;
	margin-bottom: 3rem;

	@media (min-width: 900px) {
		margin-bottom: 4rem;
	}

	&__container {
		padding-bottom: 4rem;
		padding-top: 3rem;
	}

	&__text {
		max-width: 840px;

		&--event {
			.hero-plain__copy {
				margin-top: 3.5ex;
			}

			.hero-plain__button {
				margin-top: 3.5ex;
			}
		}
	}

	&__heading {
		@include font-preset--1;
		margin: 0;
	}

	&__copy {
		@include font-preset--5;
	}

	&__register {
		@include font-preset--5;
		font-weight: 400;
		margin-top: 0.5ex;
	}

	&__date {
		@include font-preset--7;
		margin-top: 3.5ex;
	}

	&__location {
		@include font-preset--7;
		margin-top: 0.5ex;
	}

	&__categories {
		margin-top: 0.5rem;

		.categories__item {
			background-color: $color-two;
			color: $color-three;
		}
	}

	&__button {
		margin-top: 3ex;
	}
}


