.filter-table {
	margin-bottom: 10rem;

	&--accredited-colposcopists {}

	&--certified-trainers {}

	&__container {
		list-style: none;
	}

	&__row {
		display: flex;
		justify-content: space-between;
		margin: 0 0 -1px;

		> * {
			width: 50%;
			padding: 1rem;
			border: 1px solid $color-three;
			margin: 0;

			&:not(&:last-of-type) {
				border-right: 0 $color-three;
			}	
		}

		&--headings {
			background-color: $color-three;

			> * {
				@include font-preset--4;
				color: #fff;
				border: 0;
			}
		}
	}

	&__button {
		appearance: none;
		background: unset;
		cursor: pointer;
		margin: 0;
		text-align: left;
		padding-right: 2.5rem;

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: 1rem;
			transform: translateY(-50%) rotate(180deg);
			width: 1rem;
			height: 1rem;
			background-image: url('/img/icon-arrow-down.png');
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: 1rem;
		}

		&--desc {
			&::after {
				transform: translateY(-50%);
			}
		}
	}

	&__search {
		max-width: 500px;
		margin-bottom: 1rem;
	}

	&__qty {
		max-width: 500px;
		margin-bottom: 2rem;

		label {
			display: flex;
			align-items: center;
		}

		select {
			max-width: 100px;
			margin: 0 0.6rem;
		}
	}

	&__item-total {
		margin-bottom: 1.5rem;
	}

	&__pages {
		a {
			cursor: pointer;
		}
	}
	
	&__pagination-button {
		appearance: none;
		border: 1px solid;
		background: transparent;
		border-radius: 7px;
		cursor: pointer;
		padding: 0.5ex 2ex 0.7ex;
		margin-right: 0.7rem;
	}
}
