$filter-break-one: 700px;

.filter {
	margin-bottom: 3rem;

	@media (min-width: 900px) {
		margin-bottom: 4rem;
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	&__search {
		width: 100%;
		display: flex;
		margin-bottom: 1.5rem;
		
		@media (min-width: $filter-break-one) {
			width: calc(50% - 0.9rem);
			margin-bottom: 0;
		}

		input {
			height: 50px;
			width: calc(100% - 4.3rem);
			border-radius: $border-three 0 0 $border-three;
			border: 1px solid $color-three;
			padding: 0 10px;
		}

		button {
			appearance: none;
			cursor: pointer;
			border: 0;
			background-color: $color-three;
			width: 4.3rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0 $border-three $border-three 0;

			img {
				width: 1.8rem;
			}
		}
	}

	&__categories {
		width: 100%;

		@media (min-width: $filter-break-one) {
			width: calc(50% - 0.9rem);
		}
	}
}

