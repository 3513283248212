$account-break-one: 800px;

.account {
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	margin-bottom: 10rem;

	.container {
		padding-right: 0;

		@media (max-width: $account-break-one) {
			padding: 0;
		}
	}

	&__banners {
		width: 100%;
		padding: 0.5rem 1rem 2rem;
		text-align: center;
		margin: 2rem 0 3rem;
		border: 2px solid $color-four;
	}

	&__nav {
		width: 100%;
		padding: 0.8rem 1rem;
		background: $color-five;
		color: $color-three;

		@media (min-width: $account-break-one) {
			width: 25%;
			padding: 1.5rem;
		}
	}

	&__nav-list {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__nav-item {
		margin: 0;

		&:not(&:last-of-type) {
			border-bottom: 1px solid $color-two;
			margin: 0 0 0.8ex;
			padding-bottom: 0.8ex;

			@media (min-width: $account-break-one) {
				margin: 0 0 1.2ex;
				padding-bottom: 1.2ex;
			}
		}

		a {
			text-decoration: none;
		}

		&--active {
			font-weight: 800;
		}

		&--button {
			border-bottom: 0 !important;

			a {
				width: 100%;
				margin-bottom: 0.5rem;
			}
		}
	}

	&__content {
		width: 100%;

		@media (min-width: $account-break-one) {
		width: 75%;
		}
	}

	.form__col {
		.form__row {
			@media (max-width: 500px) {
				width: 100%;
			}
		}
	}
}
