.copy {
	margin-bottom: 3rem;

	@media (min-width: 900px) {
		margin-bottom: 4rem;
	}

	h2,
	h3,
	h4 {
		color: $color-three;
	}

	ul {
		list-style: none;
	}

	li {
		&::before {
			content: "";
			position: absolute;
			top: 1ex;
			left: -1.2ex;
			width: 0.5ex;
			height: 0.5ex;
			border-radius: 100%;
			background-color: $color-four;
		}
	}
}
