@use "sass:math";

/* import css normalise/reset library */
@import 'libs/normalize.scss';

/* import vars */
@import 'vars/global-vars.scss';

/* import mixins */
@import 'mixins/breakpoints.scss';
@import 'mixins/general.scss';
@import 'mixins/typography.scss';

/* import base styles */
@import 'base/@fontface.scss';
@import 'base/base.scss';
@import 'base/helpers.scss';
@import 'base/structure.scss';
@import 'base/typography.scss';

/* import components */
@import 'components/accordion.scss';
@import 'components/account.scss';
@import 'components/accredited-table.scss';
@import 'components/autocomplete.scss';
@import 'components/blockquote.scss';
@import 'components/breadcrumbs.scss';
@import 'components/buttons.scss';
@import 'components/card-four.scss';
@import 'components/card-one.scss';
@import 'components/card-three.scss';
@import 'components/card-two.scss';
@import 'components/categories.scss';
@import 'components/contact-hero.scss';
@import 'components/copy.scss';
@import 'components/filter.scss';
@import 'components/footer.scss';
@import 'components/forms.scss';
@import 'components/header.scss';
@import 'components/hero-banner.scss';
@import 'components/hero-plain.scss';
@import 'components/hero.scss';
@import 'components/images.scss';
@import 'components/intro.scss';
@import 'components/modal.scss';
@import 'components/nav-desktop.scss';
@import 'components/nav-mobile.scss';
@import 'components/pagination.scss';
@import 'components/video.scss';

/* import modules */
@import 'modules/example.scss';

/* import third party  styles */
@import 'third-party/example.scss';

/* import 'sins' file - when you need to put a quick hacky fix in */
@import 'sins';
