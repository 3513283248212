.blockquote {
	@include font-preset--9;
	
	margin-bottom: 3rem;

	@media (min-width: 900px) {
		margin-bottom: 4rem;
	}

	p {
		margin-top: 2ex;
	}

	&__container {}

	&__content {
		background-color: $color-five;
		border-radius: 0 $border-one $border-one 0;
		border-left: 4px solid $color-three;
		padding: 2rem;
	}

	&__icon {
		max-width: 1.8rem;
	}

	.blockquote__source {
		font-size: 0.87rem;
		margin-top: 3.5ex;
	}
}

