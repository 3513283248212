$contact-hero-b1: 700px;

.contact-hero {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	background-color: $color-three;
	color: $color-two;
	padding: 4rem 0;

	@media (min-width: $contact-hero-b1) {
		min-height: 37rem;
	}

	&__container {
		position: static;
	}

	&__text {
		@media (min-width: $contact-hero-b1) {
			width: calc(50% - 1.25rem);
		}
	}

	&__heading {
		@include font-preset--1;
		margin: 0;
	}

	&__copy {
		font-size: 0.87rem;
		margin-top: 2.3rem;
	}

	&__list {
		list-style: none;
		margin: 4rem 0 0;
		padding: 0;
	}

	&__list-item {
		@include font-preset--7;

		img {
			width: 1.3rem;
			position: absolute;
			top: 0;
			left: 0;
		}

		a {
			display: block;
			padding-left: 2rem;
			text-decoration: none;
		}

		&--email {}
		&--phone {}
		&--pin {}
	}

	&__image {
		display: none;

		@media (min-width: $contact-hero-b1) {
			display: block;
			position: absolute;
			right: 0;
			top: 0;
			width: calc(50% - 1.25rem);
			height: 100%;
			background-size: cover;
			background-position: 50%;
		}
	}

	&__directions {
		display: inline-block;
		width: unset;
		background-color: $color-four;
		position: absolute;
		bottom: 1.25rem;
		left: 1.25rem;
	}
}
