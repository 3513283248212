.card-three {

	&:hover {
		.card-three__heading {
			color: $color-four;
		}

		.card-three__image {
			img {
				transform: scale(1);
			}
		}
	}

	&__url {
		text-decoration: none;
	}

	&__heading {
		@include font-preset--4;
		color: $color-three;
		margin-top: 0.5ex;
		transition: color 0.2s;

	}

	&__copy {
		@include font-preset--7;
		margin-top: 0.6ex;
		color: $color-three;
	}

	&__image {
		border-radius: $border-two;
		overflow: hidden;

		img {
			transform: scale(1.1);
			transition: transform 0.2s;
		}
	}

	&__text {}

	.card-three__categories {
		margin-top: 0.3rem;

		> * {
			direction: ltr;
			white-space: nowrap;
		}
	}
}