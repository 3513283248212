.autocomplete {
	position: relative;
	display: inline-block;
	width: 500px;


	&__input {
		width: 100%;
	}

	&__results {
		display: none;
		position: absolute;
		width: 100%;
		background: #fff;
		z-index: 20;
		top: 100%;
		border: 1px solid #000;

		&.show {
			display: block;
		}
	}

	&__result {
		padding: 0.5rem 1rem;
		font-size: 0.9em;
		border-bottom: 1px solid #dedede;
	}

	&__result:hover {
		background: #dedede;
		cursor: pointer;
	}
}


