.video {
	margin-bottom: 3rem;

	@media (min-width: 900px) {
		margin-bottom: 4rem;
	}

	&__container {}

	&__button {
		cursor: pointer;
		appearance: none;
		background-color: transparent;
		border: 0;
		padding: 0;
		margin: 0;
		width: 100%;
		text-align: left;
	}
	
	&__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 4.3rem;
		z-index: 1;
		pointer-events: none;
	}

	&__caption {
		font-size: 0.87rem;
		margin-top: 1ex;
		display: inline-block;
	}
}