.modal {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.2s;
	z-index: 5;

	&--active {
		pointer-events: all;
		opacity: 1;

		.modal__container {
			transform: translate(-50%, -50%);
		}
	}

	&__container {
		max-width: 870px;
		position: absolute;
		width: 100%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, calc(-50% + 3rem));
		padding: 2rem;
		transition: transform 0.2s 0.1s;
	}

	&__inner {
		background-color: $color-two;
		padding: 1rem;
		text-align: center;
	}

	&__content {}

	

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		appearance: none;
		border: 0;
		width: 1.5rem;
		height: 1.5rem;
		background-color: $color-three;
		cursor: pointer;
		z-index: 2;
	}

	&__video {
		padding-top: 56%;

		iframe {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__copy {}

	&__button {
		margin-top: 0.8rem;

		&[href=""] {
			display: none;
		}
	}
}

