.breadcrumbs {
	display: none;

	@media (min-width: 700px) {
		display: block;
		background-color: $color-five;
		color: $color-three;
		padding: 0.5rem 0;

		&__container {
			list-style: none;
			display: flex;
			justify-content: center;
		}

		&__item {
			margin: 0;
			font-size: 0.81rem;
			margin: 0 1.5ex;

			&:not(&:last-of-type) {
				&::after {
					content: "";
					position: absolute;
					right: -2ex;
					top: 50%;
					width: 0.7ex;
					height: 0.7ex;
					transform: translate(-25%, -50%) rotate(45deg);
					box-shadow: inset -1px 1px currentColor;
				}
			}

			&:last-of-type {
				pointer-events: none;

				a {
					text-decoration: none;
				}
			}
		}
	}
}

