.images {
	margin-bottom: 3rem;

	@media (min-width: 900px) {
		margin-bottom: 4rem;
	}

	&__container {}
	&__item {}

	&__caption {
		font-size: 0.87rem;
		margin-top: 1ex;
	}
}
