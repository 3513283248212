.container {
	width: 100%;
	max-width: 72rem;
	margin: auto;
	padding-left: 2rem;
	padding-right: 2rem;

	&--two {
		max-width: 56rem;
	}
}

// Grid systems are usually a bad idea, but for this site a simple four col layout seemed appropriate
.columns {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	list-style: none;
	padding: 0;
	margin: 0 -1.25rem 2rem;

	> * {
		width: calc(100% - 2.5rem);
		margin: 0 1.25rem 2.5rem;
	}

	&--equal {
		align-items: initial;
	}

	&--four {
		> * {
			width: calc(100% - 2.5rem);

			@media (min-width: 700px) {
				width: calc(50% - 2.5rem);
			}

			@media (min-width: 1000px) {
				width: calc(25% - 2.5rem);
			}
		}
	}

	&--three {
		> * {
			width: calc(100% - 2.5rem);

			@media (min-width: 700px) {
				width: calc(50% - 2.5rem);
			}

			@media (min-width: 1000px) {
				width: calc(33.33% - 2.5rem);
			}
		}
	}

	&--two {
		> * {
			width: calc(100% - 2.5rem);

			@media (min-width: 700px) {
				width: calc(50% - 2.5rem);
			}
		}
	}
}