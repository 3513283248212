.card-two {
	border: 1px solid $color-three;
	border-radius: $border-one;
	padding: 1.6rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&__heading {
		@include font-preset--4;
		margin: 0;
		color: $color-three;
		transition: color 0.2s;
	}

	&__copy {
		@include font-preset--7;
		margin-top: 0.6ex;
	}

	&__image {
		max-width: 200px;
		margin-right: 1.8rem;
		border-radius: $border-two;
		overflow: hidden;
	}

	&__text {
		flex: 1;
		margin: 1rem 0;
	}

	&__button {
		margin-top: 1.7ex;
	}
}

