.button {
	appearance: none;
	border: 0;
	display: inline-block;
	text-decoration: none;
	font-size: 1.05rem;
	font-weight: 800;
	text-align: center;
	cursor: pointer;
	border: 1px solid $color-three;
	border-radius: $border-two;
	background-color: $color-three;
	color: $color-two;
	padding: 0.5ex 2ex 0.7ex;
	transition: background-color 0.2s, color 0.2s;

	&--two {
		background-color: $color-four;
	}

	&--plain {
		color: $color-two;
		background: unset;
		border: 0;
		text-decoration: underline;
		padding: 0.5ex 0 0.7ex;

		&:hover {
			background-color: unset !important;
			color: $color-two !important;
		}
	}

	&__small {
		width: 100%;
		font-size: 0.9rem;
		font-weight: 400;
		display: block;
		text-align: center;
	}

	&:hover {
		background-color: $color-two;
		color: $color-three;
	}
}


