$hero-banner-break-one: 750px;

.hero-banner {
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	margin-bottom: 3rem;

	@media (min-width: 900px) {
		margin-bottom: 4rem;
		min-height: 30vw;
	}

	&__container {
		padding-top: 3rem;
		padding-bottom: 10rem;

		@media (min-width: $hero-banner-break-one) {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}
	}

	&__text {
		width: 100%;
		padding: 1.5rem 2.25rem 3rem;
		background-color: $color-three;
		border-radius: $border-one;
		color: $color-two;

		@media (min-width: $hero-banner-break-one) {
			width: 50%;
		}
	}

	&__heading {
		@include font-preset--1;
		margin: 0;
		
	}

	&__copy {
		@include font-preset--5;
	}

	&__button {
		margin-top: 3ex;
	}
}
