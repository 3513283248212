$color-one: #000000;
$color-two: #ffffff;
$color-three: #3D3051;
$color-four: #836aaa; // Have tweaked color four to have an acceptible contrast ratio
$color-five: #F4F1F8;
$color-six: #4CA8BF;
$color-seven: #0CC0DF; // Random colour thats been added for the global banner - try not to use anywhere else


$border-one: 10px;
$border-two: 7px;
$border-three: 5px;

$nav-breakpoint: 950px;

$font-one: "Roboto", sans-serif;

$breakpoints: ('xsmall': '460px',
	'small': '640px',
	'medium': '768px',
	'large': '1024px',
	'xlarge': '1280px',
	'xxlarge': '1600px'
);
