input,
select,
textarea,
input:not([type="radio"]),
input:not([type="checkbox"]) {
	width: 100%;
	padding: 1ex;
}

input[type="email"] {
	text-transform: lowercase;
} 

input[type="checkbox"] {
	width: unset;
	display: inline;
}

input[readonly] {
	color: #999;
	border: 1px solid #999;

	&:focus {
		outline: none;
	}
}

label.js-error {
	color: red;
	margin-top: 1ex;
	
	&__hidden {
		display: none;
	}
}

/*
Example form field markup

<div class="form__row">
	<div class="form__field form__field--vert">
		<label class="form__label" for="region">Region</label>
		<input id="region" name="fields[region]" value="" readOnly>
	</div>
</div>

OR

<div class="form__row form__row--flex">
	<div class="form__field form__field--vert">
		<label class="form__label" for="region">Region</label>
		<input id="region" name="fields[region]" value="" readOnly>
	</div>

	<div class="form__field form__field--vert">
		<label class="form__label" for="country">Country</label>
		<input id="country" name="fields[country]" value="" readOnly>
	</div>
</div>

*/

.form {
	width: 500px;
	max-width: 100%;
	margin-bottom: 3rem;

	&--center {
		margin-left: auto;
		margin-right: auto;
	}

	&--margin {
		margin-bottom: 4rem;
	}

	&__col {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		>* {
			width: calc(50% - 0.5rem);
		}
	}

	&__row {
		margin-top: 1rem;

		&--date {
			input:focus,
			input.active {
				+ .form__date-placeholder {
					display: none;
				}
			}

			.form__date-placeholder {
				background-color: $color-two;
				pointer-events: none;
				padding: 0.5ex 1ex;
				width: 100%;
				max-width: 160px;
				position: absolute;
				z-index: 1;
				bottom: 7px;
				left: 2px;

				p {
					margin: 0;
				}
			}
		}


		&--checkbox {
			input[type="checkbox"] {
				position: absolute;
				top: 3px;
				left: 0;
			}

			.form__label {
				padding-left: 1.25rem;
			}
		}

		&--flex {
			display: flex;
			flex-direction: column;
			gap: 1rem;

			@include breakpoint('small') {
				flex-direction: row;
			}
		}
	}

	&__error {
		color: $color-one;
	}

	&__field {
		width: 100%;
		flex: 1;


		&--fullwidth {
			@include breakpoint('medium') {
				width: 100%;
			}
		}
	}

	&__label {
		display: block;
		font-size: 1rem;
		line-height: 1.2;
		margin-bottom: 0.5rem;

		&--required {
			&::after {
				content: '*';
				margin-left: 0.25em;
				color: red;
			}
		}

		&--checkbox {
			align-items: center;
			display: flex;

			input {
				margin-right: .75rem;
			}
		}
	}
	
	&__note {
		color: grey;
		display: block;
		line-height: 1.2;
		margin-bottom: 0.5rem;
	}


	&__error-list {
		margin: 0.5em 0 0 0;
		padding: 0;
		list-style: none;
		color: red;

		li {
			margin: 0 0 0.5em;
		}
	}
}

.form-error-summary {
	border-radius: .25em;
	
	&__success {
		padding: 1em;
		color: $color-two;
		background-color: $color-three;
	}

	h4 {
		margin: 0;
	}

	p {
		margin-top: 0.5ex;
	}
}


#workOrganisationOtherWrapper, #workAddressCountryOtherWrapper {
	
	margin-top: 1rem;
	
	&.hidden {
		display: none;
	}
}
