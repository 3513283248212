
@media (min-width: $nav-breakpoint) {
	.nav {
		width: 100%;
		color: $color-two;
		position: static;
		border-top: 1px solid #9E98A8;
		margin: 1.3rem 0 0;
		padding: 1rem 0 0;

		a {
			text-decoration: none;
		}

		&__back {
			display: none;
		}

		&__dropdown-trigger {
			display: none;
		}

		&__toggle {
			display: none;
		}

		&__list {
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			align-items: center;
			position: inherit;
			justify-content: center;

			> .nav__item {
				z-index: 10;
				padding-bottom: 1.1rem;
			}
		}

		&__item {
			font-size: 0.9rem;
			margin: 0 0.5rem;
			font-weight: 800;

			+ .nav__heading {
				margin-top: 3ex;
			}
			

			@media (min-width: 1150px) {
				font-size: 1.06rem;
				margin: 0 0 0 1.5rem;
			}

			&:first-of-type {
				margin: 0;
			}

			&--button {
				a {
					background-color: $color-four;
					border: 1px solid $color-four;
					border-radius: $border-two;
					padding: 0.5ex 2ex 0.7ex;
					transition: background-color 0.2s,
					color 0.2s;

					&:hover {
						background-color: $color-two;
						color: $color-four;
					}
				}
			}

			&--small {
				display: none;
			}

			&--desktop-hide {
				display: none;
			}

			&--mega {
				position: static;

				.nav__dropdown {
					width: 100%;
					// padding-top: 2.5rem;
					top: calc(100% - 0rem);

					&-wrapper {
						display: flex;
						padding: 2.5rem;
						justify-content: space-between;

						> * {
							width: calc(33.33% - 4rem);
						}
					}
				}
			}

			&:hover {
				> .nav__dropdown {
					pointer-events: all;
					opacity: 1;
					transform: translateY(0);
				}
			}
			

			&--dropdown {
				> a {
					&::after {
						content: "";
						width: 100%;
						position: absolute;
						left: 0;
						pointer-events: none;
						height: 1px;
						background-color: $color-four;
						opacity: 0;
						bottom: -8px;
						transition: opacity 0.3s, bottom 0.4s;
					}
				}

				&:hover {
					> a {
						&::after {
							bottom: -5px;
							opacity: 1;
						}
					}
				}
			}
		}

		&__dropdown {
			pointer-events: none;
			opacity: 0;
			transform: translateY(2rem);
			position: absolute;
			left: 0;
			top: 100%;
			z-index: 1;
			color: $color-three;
			width: 300px;
			// padding-top: 2.5rem;
			transition: transform 0.2s, opacity 0.1s;

			&:hover {
				pointer-events: all;
				opacity: 1;
				transform: translateY(0);
			}

			&-wrapper {
				background: $color-two;
				padding: 1.5rem;
			}

			h2 {
				margin-top: 0;
			}

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				margin-bottom: 1.5rem;
			}

			li {
				margin: 0;
			}

			.nav__item {
				@include arrowList;
			}
		}

		&__heading {
			margin: 0;

			h2 {
				font-size: 0.87rem;
				margin: 0;
				color: $color-four;
			}
		}

		&__offer {
			&-heading {
				font-size: 1.1rem;
				margin: 0;
				margin-top: 0.5rem !important;
			}

			p {
				@include arrowList;
				border-bottom: 0;
				padding-bottom: 0;
				margin: 0;
			}
		}

		&__offer-thumbnail {
			border-radius: 5px;
		}

		&__mobile-buttons {
			display: none;
		}
	}
}