.footer {
	background-color: $color-three;
	color: $color-two;
	padding: 1rem 0 2rem;

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__nav {}

	&__nav-list {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__nav-item {
		display: inline-block;
		margin: 0.8rem 0.8rem 0 0;

		a {
			text-decoration: none;
		}
	}

	&__copyright {
		margin: 0.8rem 0 0;
	}
}
